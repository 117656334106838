@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600;700;800&display=swap");

.privacy-policy-container {
  font-family: "Raleway", sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f9f9f9;
  min-height: 100vh;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
}

.privacy-header {
  background-color: #2c3e50;
  color: #fff;
  padding: 30px 0;
  text-align: center;
}

.privacy-header h1 {
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 10px;
}

.last-updated {
  font-size: 14px;
  opacity: 0.8;
}

.privacy-content {
  background-color: #fff;
  padding: 40px;
  margin: 30px 0;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.privacy-content h2 {
  font-size: 28px;
  font-weight: 700;
  margin: 30px 0 15px;
  color: #2c3e50;
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 10px;
}

.privacy-content h2:first-child {
  margin-top: 0;
}

.privacy-content p {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 300;
}

.privacy-content ul,
.privacy-content ol {
  margin: 20px 0 20px 30px;
}

.privacy-content li {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 300;
}

.privacy-content ol li {
  list-style-type: decimal;
}

.privacy-content ul li {
  list-style-type: disc;
}

.contact-info {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  margin-top: 30px;
}

.contact-info p {
  margin-bottom: 5px;
}

.privacy-footer {
  text-align: center;
  padding: 20px 0;
  color: #666;
  font-size: 14px;
  margin-top: 30px;
}

@media (max-width: 768px) {
  .privacy-content {
    padding: 20px;
  }

  .privacy-header h1 {
    font-size: 28px;
  }

  .privacy-content h2 {
    font-size: 22px;
  }

  .privacy-content p,
  .privacy-content li {
    font-size: 15px;
  }
}
