// delete-account.scss

// Variables
$primary-color: #ffffff;
$error-color: #dc2626;
$success-color: #10b981;
$button-color: #dc2626;
$button-hover: #b91c1c;
$text-color: #374151;
$border-color: #d1d5db;
$light-gray: #f3f4f6;
$medium-gray: #9ca3af;
$dark-gray: #4b5563;

.delete-account-container {
  max-width: 480px;
  margin: 40px auto;
  padding: 24px;
  background-color: $primary-color;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px;
    color: $error-color;
  }

  .error-message {
    margin-bottom: 16px;
    padding: 12px;
    background-color: rgba($error-color, 0.1);
    color: $error-color;
    border-radius: 4px;
  }

  .success-message {
    margin-bottom: 16px;
    padding: 12px;
    background-color: rgba($success-color, 0.1);
    color: $success-color;
    border-radius: 4px;
  }

  form {
    .form-group {
      margin-bottom: 16px;

      label {
        display: block;
        margin-bottom: 8px;
        font-weight: 500;
        color: $text-color;
      }

      input {
        width: 100%;
        padding: 10px 12px;
        border: 1px solid $border-color;
        border-radius: 4px;
        font-size: 16px;
        transition: border-color 0.3s ease;

        &:focus {
          outline: none;
          border-color: #3b82f6;
        }
      }

      .helper-text {
        font-size: 14px;
        color: $medium-gray;
        margin-top: 8px;
      }
    }

    .button-container {
      margin-bottom: 16px;

      &.flex {
        display: flex;
        gap: 16px;
      }

      button {
        width: 100%;
        padding: 10px 16px;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }

        &.primary-button {
          background-color: $button-color;
          color: white;

          &:hover:not(:disabled) {
            background-color: $button-hover;
          }
        }

        &.secondary-button {
          background-color: $light-gray;
          color: $dark-gray;

          &:hover:not(:disabled) {
            background-color: darken($light-gray, 5%);
          }
        }
      }
    }

    .disclaimer {
      font-size: 14px;
      color: $medium-gray;
    }
  }

  .success-screen {
    text-align: center;

    .checkmark {
      width: 64px;
      height: 64px;
      color: $success-color;
      margin: 0 auto 16px;
    }

    h3 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    p {
      color: $medium-gray;
      margin-bottom: 16px;
    }

    a {
      color: #3b82f6;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
