.userContainer {
  // width: 100%;
  // height: 90vh;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  .tableInfo {
    width: 100%;
    height: 10vh;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
    align-items: center;

    .spanstyle {
      font-weight: bold;
      font-size: 20px !important;
    }
    .ant-input-group-addon {
      button {
        height: 100% !important;
        min-height: 32px;
      }
    }

    .ant-select-selection-placeholder {
      font-size: 14px !important;
    }
    .ant-select-selection-item {
      font-size: 14px !important;
    }
    .ant-select-arrow {
      font-size: 14px !important;
    }

    .ant-btn {
      span {
        font-size: 14px !important;
      }
    }
  }

  .tableData {
    // min-height: 70vh;
    padding-left: 20px;
    padding-right: 20px;
    // margin-top: 100px;
    overflow-x: auto; /* Allow the table to scroll horizontally */
    width: 100%; /* Set the width of the table to 100% */
  }

  @media (max-width: 1000px) {
    .tableData {
      margin-top: 40px;
    }
  }

  .paginationComp {
    width: 100%;
    min-height: 6vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 20px;


    .ant-pagination-item-active {
      background-color: black !important;
    }

    svg {
      color: white !important;
    }
    a {
      color: white !important;
    }
  }
  /* Apply padding and margin styles for the entire table */
.ant-table-cell {
  padding: 7.9px !important; /* Adjust this value to reduce the padding */
}

// .custom-table {
//   width: 100%; /* or a specific width like 800px */
//   height: 100%; /* Let the height adjust automatically */
//   overflow: auto; /* Add scrolling if content exceeds height */
//   margin: 0 auto; /* Center the table */
// }

.site-layout-background {
  display: grid;
  // grid-template-columns: 1fr 350px;
  grid-gap: 0px;
}

h1 {
  font-size: 30px;
}

::-webkit-scrollbar {
  display: none;
}

.chartjs {
  width: 100%; /* This will make the chart take full width */
}

.Video-Growth-Over-Time{
  color: white;
}

.videotable-data{
  padding-left: 20px;
  padding-right: 20px;
  width:"100%";
} 
}

@media (max-width: 1000px) {
  .responsivetable {
    display: flex;
    margin-top: 100px;
    flex-direction: column; /* Stack the table vertically */
    width: 100vw; /* Make the table span the full viewport width */
    overflow-x: auto; /* Enable horizontal scrolling */
    margin-top: 60px; /* Optional: to adjust spacing */
    min-height: fit-content;
  }
}


.dashboardlinechartbuttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

@media (max-width: 1000px) {
  .dashboardlinechartbuttons {

    font-size: 12px;
    display: flex;
    justify-content: space-evenly;
  }

  .dashboardlinechartbuttons button {
    
 margin-bottom: 10px !important;
 width: 45%;

  }
}


.datepickerdashboard{
  display: flex;
   align-items: center;
    flex-wrap: wrap;
}


@media (max-width: 1000px) {
  .datepickerdashboard {

    font-size: 12px;
  }

  .datepickerdashboard {
    
 width: 60%;
  }
}



.videostablebuttons{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
}

@media (max-width: 1000px) {
  .videostablebuttons {
    justify-content: space-between;
    margin-bottom: 10px !important;
    width: 100%;
    margin-bottom: 10px !important;
  }
}