.userContainer {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  .tableInfo {
    width: 100%;
    height: 10vh;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
    align-items: center;


    .spanstyle {
      font-weight: bold;
      font-size: 20px !important;
    }
    .ant-input-group-addon {
      button {
        height: 100% !important;
        min-height: 32px;
      }
    }

    .ant-select-selection-placeholder {
      font-size: 14px !important;
    }
    .ant-select-selection-item {
      font-size: 14px !important;
    }
    .ant-select-arrow {
      font-size: 14px !important;
    }

    .ant-btn {
      span {
        font-size: 14px !important;
      }
    }
  }

  .tableData {
    // min-height: 70vh;
    padding-left: 20px;
    padding-right: 20px;
    overflow-x: auto; /* Allow the table to scroll horizontally */
    width: 100%; /* Set the width of the table to 100% */
  }

  .paginationComp {
    width: 100%;
    min-height: 6vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 20px;


    .ant-pagination-item-active {
      background-color: black !important;
    }

    svg {
      color: white !important;
    }
    a {
      color: white !important;
    }
  }

  .models {
    .modal {
      display: none; /* Hidden by default */
      position: fixed; /* Stay in place */
      z-index: 1; /* Sit on top */
      padding-top: 100px; /* Location of the box */
      left: 0;
      top: 0;
      width: 100%; /* Full width */
      height: 100%; /* Full height */
      overflow: auto; /* Enable scroll if needed */
      background-color: rgb(0, 0, 0); /* Fallback color */
      background-color: rgba(0, 0, 0, 0.4);
    }

    /* Modal Content */
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 80%;
      height: 100%;
    }

    /* The Close Button */
    .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
    }

    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
.formContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  padding-top: 20;

  .inputContainer {
    display: flex;
    justify-content: space-between;

    .spanData {
      span {
        font-weight: bold;
      }
    }

    .inputdata {
      width: 70%;
    }
  }
}

@media (max-width: 1000px) {
  .responsivetable {
    display: flex;
    flex-direction: column; /* Stack the table vertically */
    width: 100vw; /* Make the table span the full viewport width */
    overflow-x: auto; /* Enable horizontal scrolling */
    margin-top: auto; /* Optional: to adjust spacing */
  }
}

.usertablebuttons{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
}

@media (max-width: 1000px) {
  .usertablebuttons {
    justify-content: space-between;
    margin-bottom: 10px !important;
  }
}