.sponsors-page {
  padding: 24px;
  background-color: #fff;

  .sponsors-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    h1 {
      margin: 0;
      font-size: 24px;
      font-weight: 500;
    }
  }

  .preview-image {
    margin-top: 12px;

    img {
      max-width: 100%;
      max-height: 150px;
      border-radius: 4px;
    }
  }
}

// Modal styles
.ant-modal-body {
  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-upload-list {
    margin-top: 8px;
  }
}
