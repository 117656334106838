.site-layout-background {
  display: grid;
  /* grid-template-columns: 1fr 350px; */
  grid-gap: 20px;
  margin-top: 20px;
}
h1 {
  font-size: 30px;
}
::-webkit-scrollbar {
  display: none;
}


.inputFlexss {
  display: flex;
  justify-content: space-around;
  margin-bottom: 25px;
  gap: 20px;
  flex-direction: row; /* Default for larger screens */
}

@media (max-width: 1000px) {
  .inputFlexss {
    flex-direction: column; /* Change to column for screens smaller than 1000px */
    width: 100vw;
    
  }
}

@media (max-width: 1000px) {
  .siderrr {
    display: none;
  }
}


@media (max-width: 1000px) {
  .responsivetable {
    flex-direction: column;
    width: 100vw;
  }
}


.dashboardlinechartbuttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

@media (max-width: 1000px) {
  .dashboardlinechartbuttons {

    font-size: 12px;
    display: flex;
    justify-content: space-evenly;
  }

  .dashboardlinechartbuttons button {
    
 margin-bottom: 10px !important;
 width: 45%;

  }
}


.datepickerdashboard{
  display: flex;
   align-items: center;
    flex-wrap: wrap;
}


@media (max-width: 1000px) {
  .datepickerdashboard {

    font-size: 12px;
  }

  .datepickerdashboard {
    
 width: 60%;
  }
}