.userContainer {
  // width: 100%;
  // min-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .test {
    background: white;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
    margin-top: 50px;
    .ant-table {
      min-height: 200px !important;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .tableInfo {
    width: 100%;
    height: 10vh;
    min-height: 100px !important;

    display: flex;
    justify-content: space-between !important;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;

    .spanstyle {
      font-weight: bold;
      font-size: 20px !important;
    }
    .ant-input-group-addon {
      button {
        height: 100% !important;
        min-height: 32px;
      }
    }

    .ant-select-selection-placeholder {
      font-size: 14px !important;
    }
    .ant-select-selection-item {
      font-size: 14px !important;
    }
    .ant-select-arrow {
      font-size: 14px !important;
    }

    .ant-btn {
      span {
        font-size: 14px !important;
      }
    }
  }

  .tableDatac {
    // min-height: 50vh;
    padding-left: 20px;
    padding-right: 20px;

    .ant-table {
      min-height: 50vh !important;
    }
  }

  .paginationComp {
    width: 100%;
    min-height: 6vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 20px;

    .ant-pagination-item-active {
      background-color: black !important;
    }

    svg {
      color: white !important;
    }
    a {
      color: white !important;
    }
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  padding-top: 20;

  .inputContainer {
    display: flex;
    justify-content: space-between;

    .spanData {
      span {
        font-weight: bold;
      }
    }

    .inputdata {
      width: 70%;
    }
  }
}

@media (max-width: 1000px) {
  .responsivetable {
    display: flex;
    flex-direction: column; /* Stack the table vertically */
    width: 100vw; /* Make the table span the full viewport width */
    overflow-x: auto; /* Enable horizontal scrolling */
    margin-top: auto; /* Optional: to adjust spacing */
  }
}
